import React, { useState } from 'react';
import { Modal, TouchableOpacity } from 'react-native';
import { WebView } from 'react-native-webview';
import * as Yup from 'yup';
import { language, t } from '../../services/translations';
import { HypertextLink } from '../../components/atomic/HypertextLink';
import { AppButton, Type } from '../../components/atomic/AppButton';
import { alertInfo } from '../../services/utils';
import { useDispatch } from 'react-redux';
import { match } from 'ts-pattern';
import { loadAppState, saveLanguage, saveToken } from '../../services/storage';
import { Space1 } from '../../styles/spaces';
import { login, ErrorReason, getAuthenticationMode, redirectToAuthenticateWithSso } from '../../services/api/login';
import { AppLanguagePicker } from '../../components/atomic/AppLanguagePicker';
import { ScreenNames } from '../../ScreenNames';
import { setUser } from '../../store/user';
import { LoginField } from '../../components/atomic/formik/LoginField';
import { MaxWidthScreen } from '../../components/containers/MaxWidthScreen';
import { Platform, StyleSheet, View } from 'react-native';
import Logo from '../../components/atomic/Logo';
import { AuthenticationMode } from '../../entities/AuthenticationMode';
import { FormikContext, FormikHelpers, useFormik } from 'formik';
import { apiUrlBase } from '../../../configuration';
import { Header, Icon } from '@rneui/themed';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { setLoading } from '../../store/action';

export const schema = Yup.object({
  email: Yup.string().min(1).required(t('field_required')),
  password: Yup.string().min(4).required(t('field_required')),
});

type FormValues = {
  email: string;
  password: string;
};

export function Login(props: { navigation: any }) {
  let [, forceRefresh] = useState({});
  const dispatch = useDispatch();

  const [authenticationMode, setAuthenticationMode] = useState<null | AuthenticationMode>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [webViewUrl, setWebViewUrl] = useState<string | null>(null);

  const handleSubmit = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    try {
      var userData = await login(values.email, values.password);
      await saveToken(userData.token);
      setUser({ ...userData }, dispatch);
    } catch (err: any) {
      if (err.message === ErrorReason.INVALID_CREDENTIALS) {
        formikHelpers.setFieldError('email', t('invalid_credentials'));
      } else if (err.message === ErrorReason.MAIL_NOT_VALIDATED) {
        formikHelpers.setFieldError('email', t('mail_not_validated'));
      } else {
        alertInfo(t('unknown_error'));
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: handleSubmit as any,
    validationSchema: schema,
    validateOnMount: true,
  });

  const fetchAuthenticationMode = async (email: string) => {
    try {
      setIsLoading(true);
      let mode = await getAuthenticationMode(email);

      match(mode as AuthenticationMode)
        .with(AuthenticationMode.EmailAndPassword, () => setAuthenticationMode(mode))
        .with(AuthenticationMode.Sso, () => {
          if (Platform.OS === 'android') {
            // On android, redirection is not always working
            // So we use a webview to authenticate instead of a redirection
            setWebViewUrl(`${apiUrlBase}Auth/authenticate/sso?email=${email}`);
          } else {
            redirectToAuthenticateWithSso(email);
          }
        })
        .otherwise(() => {
          formik.setFieldError('email', t('invalid_email'));
        });
    } catch (err) {
      setIsLoading(false);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormikContext.Provider value={formik}>
      <MaxWidthScreen>
        <View style={styles.container}>
          <Logo />
        </View>
        <View style={styles.container}>
          <LoginField
            emailName="email"
            passwordName="password"
            passwordVisible={authenticationMode === AuthenticationMode.EmailAndPassword}
            isLoading={isLoading}
            onNext={fetchAuthenticationMode}
            onEmailChange={() => {
              formik.setFieldValue('password', '');
              setAuthenticationMode(null);
            }}
          />
          <Space1 />
          <AppLanguagePicker
            containerStyle={styles.languagePicker}
            onLanguageChanged={(lng) => {
              saveLanguage(lng).then(() => {
                forceRefresh({});
              });
            }}
            defaultValue={language()}
          />
          <Space1 />
          <HypertextLink
            onPress={() => props.navigation.navigate(ScreenNames.ResetPasswordSendCode)}
            text={t('forgotten_password')}
          />
        </View>
        <View style={styles.container} />
        <AppButton
          title={t('register_button')}
          onPress={() => props.navigation.navigate(ScreenNames.Registration)}
          enabled={true}
          type={Type.LIGHT}
        />
        {webViewUrl && (
          <Modal>
            <Header
              backgroundColor="white"
              leftComponent={
                <TouchableOpacity onPress={() => setWebViewUrl(null)}>
                  <Icon name="arrow-left" type="feather" color={PRIMARY_COLOR} />
                </TouchableOpacity>
              }
              leftContainerStyle={styles.headingLeftContainer}
            />
            <WebView
              source={{ uri: webViewUrl }}
              onNavigationStateChange={(state) => {
                if (state.url.includes('/main?accessToken=')) {
                  const asyncFunction = async () => {
                    dispatch(setLoading(true));
                    setWebViewUrl(null);

                    try {
                      let token = state.url.split('accessToken=')[1];
                      await saveToken(token);
                      await loadAppState(dispatch);
                    } catch (err) {
                    } finally {
                      dispatch(setLoading(false));
                    }
                  };

                  asyncFunction();
                }
              }}
            />
          </Modal>
        )}
      </MaxWidthScreen>
    </FormikContext.Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  languagePicker: {
    alignSelf: 'center',
    width: 200,
  },
  headingLeftContainer: {
    justifyContent: 'center',
  },
});
