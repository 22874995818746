import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { TopAuthor } from '../../entities/TopAuthor';
import AppText from '../atomic/AppText';
import { t } from '../../services/translations';
import { PRIMARY_COLOR } from '../../styles/appColor';
import Table from '../atomic/Table';
import { AppLoader } from '../atomic/AppLoader';

export type TopAuthorChartProps = {
  data: TopAuthor[] | undefined;
  isLoading: boolean;
};

export default function TopAuthorChart({ data, isLoading }: TopAuthorChartProps) {
  const [chartWidth, setChartWidth] = useState<number>(0);
  const maxCount = data?.reduce((acc, author) => Math.max(acc, author.contributionCount), 0) || 1;

  console.log('TopAuthorChart', data);

  return (
    <>
      {isLoading && (
        <View>
          <AppLoader />
        </View>
      )}
      <Table style={[styles.table, isLoading && styles.hidden]}>
        <Table.Row>
          <Table.Header column={0} text={t('last_name')} />
          <Table.Header column={1} text={t('service')} />
          <Table.Header column={2} text={t('geo_site_short')} />
          <Table.Header column={3} text={t('count')} />
          <Table.Header
            column={4}
            text={''}
            style={styles.chartCell}
            ignoreMinWidth
            onLayout={(event) => {
              setChartWidth(event.nativeEvent.layout.width);
            }}
          />
        </Table.Row>
        {!isLoading &&
          data &&
          data.map((author) => (
            <Table.Row key={author.id}>
              <Table.Cell column={0}>
                <AppText>{author.name}</AppText>
              </Table.Cell>
              <Table.Cell column={1}>
                <AppText>{author.serviceName}</AppText>
              </Table.Cell>
              <Table.Cell column={2}>
                <AppText>{author.siteName}</AppText>
              </Table.Cell>
              <Table.Cell column={3}>
                <AppText>{author.contributionCount}</AppText>
              </Table.Cell>
              <Table.Cell column={4} ignoreMinWidth style={styles.chartCell}>
                <View
                  style={[
                    styles.chartBar,
                    {
                      width: Math.max((author.contributionCount / maxCount) * (chartWidth - 16), 0),
                    },
                  ]}
                />
              </Table.Cell>
            </Table.Row>
          ))}
      </Table>
    </>
  );
}

const styles = StyleSheet.create({
  hidden: {
    opacity: 0,
  },
  table: {
    paddingBottom: 20,
  },
  textCell: {
    maxWidth: 200,
  },
  chartCell: {
    minWidth: 100,
    flex: 1,
  },
  chartBar: {
    height: 20,
    backgroundColor: PRIMARY_COLOR,
  },
});
