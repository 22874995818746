import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import * as Yup from 'yup';
import AppText, { Mode } from '../atomic/AppText';
import { t } from '../../services/translations';
import AvatarField from '../atomic/formik/AvatarField';
import { Header2 } from '../containers/Header2';
import { ResponsiveRow } from '../containers/ResponsiveRow';
import { ResponsiveColumn } from '../containers/ResponsiveColumn';
import TextField from '../atomic/formik/TextField';
import RadioGroupField from '../atomic/formik/RadioGroupField';
import { Space2 } from '../../styles/spaces';
import SwitchField from '../atomic/formik/SwitchField';
import SubmitButton from '../atomic/formik/SubmitButton';
import { LanguagePickerField } from '../atomic/formik/LanguagePickerField';
import { SelectField } from '../atomic/formik/SelectField';
import {
  CompanyGeoSiteReduced,
  CompanyGroupReduced,
  CompanyServiceReduced,
  getCompanyGeoSites,
  getCompanyServices,
  getLoggedUserCompanyGroups,
} from '../../services/api/company.api';
import { emailRegex } from '../../services/formUtil';
import { useField, useFormikContext } from 'formik';
import useLoggedUser from '../../hooks/useLoggedUser';
import DateField from '../atomic/formik/DateField';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { differenceInYears } from 'date-fns';

export const schema = Yup.object({
  email: Yup.string().min(1).required(t('field_required')).matches(emailRegex, t('invalid_email')),
  firstName: Yup.string().min(1).required(t('field_required')),
  lastName: Yup.string().min(1).required(t('field_required')),
  service: Yup.number().required(t('field_required')),
  geoSite: Yup.number().required(t('field_required')),
  languageCode: Yup.string().required(t('field_required')),
});

export type UserFormFieldsProps = {
  title?: string;
  displayEmailWarning?: boolean;
  allowAvatarEdit?: boolean;
  allowEmailEdit?: boolean;
  displayHideEmail?: boolean;
  displayGender?: boolean;
};

export default function UserFormFields({
  title,
  displayEmailWarning,
  allowAvatarEdit,
  allowEmailEdit,
  displayHideEmail,
  displayGender,
}: UserFormFieldsProps) {
  const user = useLoggedUser();
  const userToken = user?.token;
  const companyId = user?.entrepriseId;
  const initialValues = useFormikContext<any>().initialValues;
  const [{ value: seniorityDate }] = useField<Date | null>('seniorityDate');

  const [companyServices, setCompanyServices] = useState<CompanyServiceReduced[]>([]);
  const [companyGeoSites, setCompanyGeoSites] = useState<CompanyGeoSiteReduced[]>([]);
  const [companyGroups, setCompanyGroups] = useState<CompanyGroupReduced[]>([]);

  useEffect(() => {
    const asyncFunc = async () => {
      const companyServicePromise = getCompanyServices(companyId, userToken!).then(setCompanyServices);
      const companyGeoSitesPromise = getCompanyGeoSites(companyId, userToken!).then(setCompanyGeoSites);
      const companyGroupsPromise = getLoggedUserCompanyGroups(userToken!).then(setCompanyGroups);

      await Promise.all([companyServicePromise, companyGeoSitesPromise, companyGroupsPromise]);
    };

    asyncFunc();
  }, [userToken, companyId]);

  const seniorityYears = seniorityDate && differenceInYears(new Date(), seniorityDate);

  return (
    <>
      {title && <AppText style={styles.title}>{title}</AppText>}
      {allowAvatarEdit && <AvatarField name="photo" />}
      <Header2>{t('personal_information')}</Header2>
      <ResponsiveRow>
        <ResponsiveColumn first={true}>
          <TextField
            name="email"
            required
            mandatory={allowEmailEdit}
            displayOnly={!allowEmailEdit && !!initialValues.email && initialValues.email !== ''}
            title={t('email')}
          />
        </ResponsiveColumn>
      </ResponsiveRow>
      {displayHideEmail && (
        <ResponsiveRow>
          <ResponsiveColumn>
            <View style={styles.switchContainer}>
              <SwitchField name="hideEmail" style={{ transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }} />
              <AppText style={styles.switchLabel}>{t('hide_email')}</AppText>
            </View>
          </ResponsiveColumn>
        </ResponsiveRow>
      )}
      <ResponsiveRow>
        <ResponsiveColumn first={true}>
          <TextField
            name="firstName"
            required
            mandatory={allowEmailEdit}
            displayOnly={!allowEmailEdit && !!initialValues.firstName && initialValues.firstName !== ''}
            title={t('first_name')}
          />
        </ResponsiveColumn>
        <ResponsiveColumn>
          <TextField
            name="lastName"
            required
            mandatory={allowEmailEdit}
            displayOnly={!allowEmailEdit && !!initialValues.lastName && initialValues.lastName !== ''}
            title={t('last_name')}
          />
        </ResponsiveColumn>
      </ResponsiveRow>
      <ResponsiveRow>
        <ResponsiveColumn first={true}>
          <TextField name="landlineNumber" title={t('landline_number')} />
        </ResponsiveColumn>
        <ResponsiveColumn>
          <TextField name="mobileNumber" title={t('mobile_number')} />
        </ResponsiveColumn>
      </ResponsiveRow>
      <View style={styles.switchContainer}>
        <SwitchField name="hidePhone" style={{ transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }} />
        <AppText style={styles.switchLabel}>{t('hide_phone')}</AppText>
      </View>
      {displayGender && (
        <ResponsiveRow>
          <ResponsiveColumn first={true}>
            <RadioGroupField
              name="gender"
              title={t('genre')}
              options={[
                { label: t('woman'), value: 'F' },
                { label: t('man'), value: 'H' },
              ]}
              vertical
            />
          </ResponsiveColumn>
        </ResponsiveRow>
      )}

      <Space2 />
      <Header2>{t('important_dates')}</Header2>
      <ResponsiveRow>
        <ResponsiveColumn first={true}>
          <DateField name="birthDate" title={t('birth_date')} />
        </ResponsiveColumn>
        <ResponsiveColumn>
          <DateField name="seniorityDate" title={t('seniority_date')} />
        </ResponsiveColumn>
        <ResponsiveColumn>
          {seniorityDate && seniorityYears && seniorityYears > 0 && (
            <View style={styles.seniorityContainer}>
              <AppText style={styles.seniorityLabel} mode={Mode.BOLD}>
                {seniorityYears > 1
                  ? t('seniority_x_years', { count: seniorityYears })
                  : t('seniority_x_year', { count: seniorityYears })}
              </AppText>
            </View>
          )}
        </ResponsiveColumn>
      </ResponsiveRow>

      <Space2 />
      <Header2>{t('professional_information')}</Header2>

      <ResponsiveRow>
        <ResponsiveColumn first={true}>
          <TextField
            name="jobTitle"
            title={t('job_title')}
            displayOnly={!allowEmailEdit && !!initialValues.lastName && initialValues.lastName !== ''}
          />
        </ResponsiveColumn>
        <ResponsiveColumn />
      </ResponsiveRow>

      <ResponsiveRow>
        <ResponsiveColumn first={true}>
          <SelectField
            name="service"
            required
            title={t('service_faculty')}
            style={styles.flex}
            data={companyServices.map((v) => {
              return { label: v.libelle, value: v.id };
            })}
            displayOnly={!allowEmailEdit && !!initialValues.service}
          />
        </ResponsiveColumn>
        <ResponsiveColumn>
          <SelectField
            name="geoSite"
            required
            title={t('geo_site')}
            data={companyGeoSites.map((s) => {
              return { label: s.geoSite, value: s.id };
            })}
            displayOnly={!allowEmailEdit && !!initialValues.geoSite}
          />
        </ResponsiveColumn>
        <ResponsiveColumn>
          <SelectField
            name="group"
            title={t('group')}
            data={companyGroups.map((s) => {
              return { label: s.label, value: s.id };
            })}
            displayOnly
          />
        </ResponsiveColumn>
      </ResponsiveRow>

      <ResponsiveRow>
        <ResponsiveColumn first={true}>
          <LanguagePickerField name="languageCode" title={t('language')} required />
        </ResponsiveColumn>
      </ResponsiveRow>
      {displayEmailWarning && (
        <ResponsiveRow>
          <ResponsiveColumn>
            <AppText>{t('take_care_email_exists')}</AppText>
          </ResponsiveColumn>
        </ResponsiveRow>
      )}
      <SubmitButton text={t('save')} />
    </>
  );
}

const styles = StyleSheet.create({
  title: {
    marginVertical: 22,
    fontSize: 20,
    textAlign: 'center',
  },
  switchContainer: {
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
    alignItems: 'center',
  },
  switchLabel: {
    marginLeft: 10,
    fontSize: 12,
  },
  seniorityContainer: {
    flex: 1,
    marginLeft: 10,
    justifyContent: 'center',
  },
  seniorityLabel: {
    color: PRIMARY_COLOR,
  },
  flex: {
    flex: 1,
  },
});
