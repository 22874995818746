import { DrawerScreenProps } from '@react-navigation/drawer';
import { Icon } from '@rneui/base';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDispatch } from 'react-redux';
import { AppButton, Type } from '../../../../components/atomic/AppButton';
import { AppCheckbox } from '../../../../components/atomic/AppCheckbox';
import AppSwitch from '../../../../components/atomic/AppSwitch';
import AppText, { Mode } from '../../../../components/atomic/AppText';
import { FormField, FormFieldPicker, RTEFormField, TextFormField } from '../../../../components/atomic/FormField';
import LanguageSelector from '../../../../components/atomic/LanguageSelector';
import PublicationModePicker from '../../../../components/atomic/PublicationModePicker';
import { DefaultBackground } from '../../../../components/containers/AppScreen';
import FormLoading from '../../../../components/containers/FormLoading';
import PhotoFormField from '../../../../components/containers/PhotoFormField';
import PickUsersForm from '../../../../components/containers/PickUsersForm';
import useCategories from '../../../../hooks/useCategories';
import useEditCompanyNews from '../../../../hooks/useEditCompanyNews';
import { getLibelleFromCode } from '../../../../languages/language_mapping';
import { CompanyNewsUpload } from '../../../../models/CompanyNews';
import { getContributionImageSource, getDocumentURI } from '../../../../services/api/helper.api';
import { postCompanyNews, putCompanyNews } from '../../../../services/api/news.contributions.api';
import { NEWS_PUBLICATION_MODE_ENABLED } from '../../../../services/features';
import { availableLanguages, t } from '../../../../services/translations';
import {
  alertInfo,
  hasSubAdminRight,
  isAdmin,
  isMobile,
  PickPhotoOrDocumentResult,
  sendSuccessText,
  uriToFile,
} from '../../../../services/utils';
import { setContributionsListNeedsRefresh, setLoading } from '../../../../store/action';
import { ContributionType } from '../../../../types';
import { ExtractUri } from '../../../../utils/Medias';
import useLoggedUser from '../../../../hooks/useLoggedUser';

type ScreenProps = DrawerScreenProps<any>;

export default function CompanyNewsFormScreen(props: ScreenProps) {
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const [photo, setPhoto] = useState<PickPhotoOrDocumentResult | null>(null);
  const create = !props.route.params?.id || props.route.params?.action === 'duplicate';
  const { data, loading, onChange, valid } = useEditCompanyNews(props.route.params?.id);
  const { categories } = useCategories(ContributionType.COMPANY_NEWS);

  useEffect(() => {
    if (data.videoUrl === '' && !!data?.videoUrl) {
      onChange('videoUrl')(ExtractUri(data.videoUrl));
    }
  }, [onChange, data.videoUrl]);

  // If were duplicating, we need to properly initialize the image/document
  // If the image is a video, we ignore it cause the server will take care of pulling the cover
  useEffect(() => {
    if (!create || data.videoUrl) {
      return;
    }

    if (data.document && data.documentId) {
      // Fake a file by creating an empty File object
      const file = new File([], data.document.name);
      file.uri = getDocumentURI(data.documentId);
      file.fileName = data.document.name;

      setPhoto(file);
    } else if (data.image && data.imageId) {
      // Fake a file by creating an empty File object
      const file = new File([], data.image.name);
      file.uri = getDocumentURI(data.imageId);
      file.fileName = data.image.name;

      setPhoto(file);
    }
  }, [create, data.imageId, data.image, data.documentId, data.document, data.videoUrl, setPhoto]);

  const submitButtonText = useMemo(() => {
    if (create) {
      if (
        !isAdmin(user) &&
        !hasSubAdminRight(user, data.entrepriseServiceIds, data.entrepriseSiteGeoIds, data.entrepriseGroupIds)
      ) {
        return t('send_for_validation');
      }

      return t('publish_button');
    } else {
      return t('update_button');
    }
  }, [create, data.entrepriseGroupIds, data.entrepriseServiceIds, data.entrepriseSiteGeoIds, user]);

  const showEmailActivated = useMemo(() => {
    const now = new Date();
    const visibleStartDate = data.visibleStartDate && new Date(data.visibleStartDate);

    return (
      data.publicationMode === 'immediate_posting' || !visibleStartDate || visibleStartDate.getTime() < now.getTime()
    );
  }, [data.publicationMode, data.visibleStartDate]);

  if (loading) {
    return <FormLoading />;
  }

  const submit = () => {
    const hasRights =
      isAdmin(user) ||
      hasSubAdminRight(user, data.entrepriseServiceIds, data.entrepriseSiteGeoIds, data.entrepriseGroupIds);
    const body: CompanyNewsUpload = { ...data };

    if (body.publicationMode === 'immediate_posting') {
      body.visibleStartDate = null;
      body.visibleEndDate = null;
    } else if (body.publicationMode === 'planned_with_start_date') {
      body.visibleEndDate = null;
    }

    if (!showEmailActivated) {
      body.emailActivated = false;
    }

    const onSubmitted = () => {
      dispatch(setLoading(false));
      alertInfo(sendSuccessText(user, ContributionType.COMPANY_NEWS, hasRights));
      dispatch(setContributionsListNeedsRefresh(true));
      props.navigation.goBack();
    };

    dispatch(setLoading(true));

    const promise = create
      ? postCompanyNews(user, body, photo || undefined)
      : putCompanyNews(user, props.route.params?.id, body, photo || undefined);
    promise.then(onSubmitted).catch(() => {
      alertInfo(t('error_occurred'));
      dispatch(setLoading(false));
    });
  };

  return (
    <DefaultBackground style={styles.background}>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="never"
        style={styles.container}
        keyboardDismissMode="on-drag"
      >
        <View style={styles.imageContainer}>
          <PhotoFormField
            defaultPhoto={getContributionImageSource(data.fichierId, ContributionType.COMPANY_NEWS)}
            photo={photo}
            videoUrl={data.videoUrl}
            allowPdf
            onPhotoChanged={setPhoto}
            onVideoUrlChanged={onChange('videoUrl')}
          />
        </View>
        <TextFormField mandatory onChangeText={onChange('titre')} value={data.titre} title={t('title') + '*'} />
        <RTEFormField onChangeText={onChange('texte')} defaultValue={data.texte} title={t('description') + '*'} />
        <FormField title={t('translations')}>
          {data.translations!.map((tr, k) => {
            return (
              <View key={k} style={styles.translationContainer}>
                <View style={styles.translationHeaderContainer}>
                  <AppText mode={Mode.BOLD} style={styles.translationTitle}>
                    {getLibelleFromCode(tr.lng)}
                  </AppText>
                  <TouchableOpacity
                    onPress={() => {
                      let newTranslations = data.translations!.filter((el) => el.lng !== tr.lng);
                      onChange('translations')(newTranslations);
                    }}
                  >
                    <Icon name="close" />
                  </TouchableOpacity>
                </View>
                <TextFormField
                  mandatory
                  onChangeText={(value) => {
                    let newTranslations = data.translations!.map((el) =>
                      el.lng === tr.lng ? { ...el, titre: value } : el
                    );
                    onChange('translations')(newTranslations);
                  }}
                  value={tr.titre}
                  title={t('title') + ' (' + tr.lng + ')*'}
                />
                <RTEFormField
                  onChangeText={(value) => {
                    let newTranslations = data.translations!.map((el) =>
                      el.lng === tr.lng ? { ...el, description: value } : el
                    );
                    onChange('translations')(newTranslations);
                  }}
                  defaultValue={tr.description}
                  title={t('description') + ' (' + tr.lng + ')*'}
                />
              </View>
            );
          })}
          <View style={styles.availableLanguageContainer}>
            {availableLanguages
              .filter((e) => data.translations!.findIndex((x) => x.lng === e.code) < 0)
              .map((e) => (
                <LanguageSelector
                  key={e.code}
                  language={e}
                  setTranslations={onChange('translations')}
                  texte={data.texte}
                  titre={data.titre}
                  translations={data.translations}
                />
              ))}
          </View>
          <View style={styles.featuredContainer}>
            <AppCheckbox title={t('featured')} onValueChange={onChange('featured')} value={data.featured ?? false} />
          </View>
          <PublicationModePicker
            mode={data.publicationMode}
            visibleStartDate={data.visibleStartDate}
            visibleEndDate={data.visibleEndDate}
            onChangeMode={onChange('publicationMode')}
            onChangeStartDate={onChange('visibleStartDate')}
            onChangeEndDate={onChange('visibleEndDate')}
          />
        </FormField>
        <PickUsersForm onChange={onChange} data={data} />
        <FormFieldPicker<string>
          style={styles.categoryField}
          title={t('categories')}
          displayOnly={NEWS_PUBLICATION_MODE_ENABLED}
          defaultValue={data.categoryId?.toString()}
          onChangeText={(value) => {
            if (value === t('select_an_item')) {
              onChange('categoryId')(null);
            } else {
              onChange('categoryId')(isNaN(parseInt(value, 10)) ? null : parseInt(value, 10));
            }
          }}
          data={categories.map((category) => ({ label: category.name, value: category.id.toString() }))}
        />
        <View style={styles.switchContainer}>
          <View style={styles.switchField}>
            <AppSwitch
              onValueChange={onChange('commentsActivated')}
              value={!!data?.commentsActivated}
              style={styles.switch}
            />
            <AppText>{t('authorize_comments')}</AppText>
          </View>
          <View style={styles.switchField}>
            <AppSwitch
              onValueChange={onChange('notifActivated')}
              value={!!data?.notifActivated}
              style={styles.switch}
            />
            <AppText>{t('generate_notif')}</AppText>
          </View>
          {showEmailActivated && isAdmin(user) && (
            <View style={styles.switchFieldLast}>
              <AppSwitch
                onValueChange={onChange('emailActivated')}
                value={!!data?.emailActivated}
                style={styles.switch}
              />
              <AppText>{t('generate_email')}</AppText>
            </View>
          )}
        </View>
        <View style={styles.submitContainer}>
          <AppButton title={submitButtonText} type={Type.PRIMARY} onPress={submit} enabled={valid} />
        </View>
      </KeyboardAwareScrollView>
    </DefaultBackground>
  );
}

const styles = StyleSheet.create({
  background: { padding: isMobile() ? 10 : 30 },
  container: { padding: 10 },
  imageContainer: { flex: 1, flexDirection: 'column', alignItems: 'center' },
  translationContainer: { borderTopWidth: 1 },
  translationHeaderContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
  translationTitle: {
    marginBottom: 10,
    fontSize: 20,
    textAlign: 'center',
    flex: 1,
    marginTop: 10,
  },
  availableLanguageContainer: { flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 },
  featuredContainer: { marginVertical: 20 },
  categoryField: { marginTop: 24 },
  switchContainer: { flexDirection: 'row', flexWrap: 'wrap' },
  switchField: { flexDirection: 'row', marginTop: 40, marginRight: 40 },
  switchFieldLast: { flexDirection: 'row', marginTop: 40 },
  switch: { marginRight: 28 },
  submitContainer: { marginTop: 30, marginBottom: 100 },
});
