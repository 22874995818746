import React, { useCallback, useEffect, useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { match } from 'ts-pattern';
import { Chip } from '../../components/atomic/Chip';
import Zoom from '../../components/atomic/Zoom';
import { AppScreen } from '../../components/containers/AppScreen';
import HorizontalFlatList from '../../components/containers/HorizontalList';
import { OrganizationalChart } from '../../entities/OrganizationalChart';
import { contributionColors } from '../../services/api/contributions';
import { getDocumentURI } from '../../services/api/helper.api';
import { getOrganizationalCharts } from '../../services/api/organizational.chart.api';
import { t } from '../../services/translations';
import { alertInfo } from '../../services/utils';
import { setLoading } from '../../store/action';
import useLoggedUser from '../../hooks/useLoggedUser';
import { Pdf } from '../../components/atomic/Pdf';

const colorList = Object.values(contributionColors);

export default function OrganizationChartsScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<OrganizationalChart[]>([]);
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const [selectedChart, setSelectedChart] = useState<OrganizationalChart | null>(null);

  const refresh = useCallback(async () => {
    setIsLoading(true);

    try {
      setItems(await getOrganizationalCharts(user));
    } catch (e) {
      alertInfo(t('unknown_error'));
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  const currentChart = selectedChart || items[0] || null;
  const contentType = currentChart?.file?.format;

  return (
    <View style={styles.container}>
      <View style={styles.labelContainers}>
        <HorizontalFlatList
          data={items}
          keyExtractor={(item) => item.id.toString()}
          renderItem={({ item, index }) => (
            <Chip
              key={item.id}
              text={item.label}
              style={styles.chip}
              color={colorList[index % colorList.length]}
              onPress={() => setSelectedChart(item)}
            />
          )}
        />
      </View>
      <AppScreen style={styles.content}>
        {currentChart && match(contentType)
          .with('application/pdf', () => (
            <Pdf source={getDocumentURI(currentChart.fileId)} style={styles.image} fit="page" />
          ))
          .otherwise(() => (
            <Zoom>
              <Image source={{ uri: getDocumentURI(currentChart.fileId) }} style={styles.image} />
            </Zoom>
          ))}
      </AppScreen>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  labelContainers: {
    marginVertical: 10,
    paddingLeft: 10,
  },
  chip: {
    marginRight: 10,
  },
  content: {
    paddingBottom: 10,
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
});
