import axios from 'axios';
import { headers } from './base';
import { apiUrlBase } from '../../../configuration';
import * as Notifications from 'expo-notifications';
import { Notification } from '../../entities/Notification';
import { UserSaved } from '../storage';
import { match } from 'ts-pattern';
import { Platform } from 'react-native';
import { UserTokenSource } from '../../entities/UserToken';

export function markAsReadNotification(notificationId: number): Promise<Response> {
  return axios.get(apiUrlBase + 'notifications/read/' + notificationId);
}

export async function readUserNotifications(userId: number) {
  return axios.get(`${apiUrlBase}notifications/user/${userId}/read-all`);
}

export async function getNotificationsCount(user: UserSaved): Promise<number> {
  const response = await axios.get(`${apiUrlBase}notifications/user/${user.id}/count`, {
    headers: headers(user.token),
  });

  const count = (await response.data) as number;
  try {
    await Notifications.setBadgeCountAsync(count);
  } catch {}

  return count;
}

export async function getNotifications(user: UserSaved): Promise<Notification[]> {
  return axios
    .get(apiUrlBase + 'Notifications/user/' + user.id, {
      headers: headers(user.token),
    })
    .then((r) => r.data);
}

export function updateDeviceToken(user: UserSaved, deviceToken: string) {
  var source = match(Platform.OS)
    .with('android', () => UserTokenSource.ANDROID)
    .with('ios', () => UserTokenSource.IOS)
    .otherwise(() => UserTokenSource.WEB);

  return axios.post(
    apiUrlBase + 'UserTokens',
    {
      userId: user.id,
      deviceToken: deviceToken,
      source,
    },
    {
      headers: headers(user.token),
    }
  );
}

export function deleteDeviceToken(token: string, deviceToken: string) {
  return axios.delete(apiUrlBase + 'UserTokens', {
    headers: headers(token),
    data: {
      userId: 0,
      deviceToken: deviceToken,
    },
  });
}
