import { useCallback } from 'react';
import useLoggedUser from './useLoggedUser';
import { hasLicence } from '../services/licences';

export default function useIsSsoEmail() {
  const loggedUser = useLoggedUser();

  return useCallback(
    (email: string) => {
      if (!email) {
        return false;
      }

      const entreprise = loggedUser.entreprise;
      const hasSsoLicence = hasLicence(loggedUser, (x) => x.ssoAuth);

      if (!hasSsoLicence) {
        return false;
      }

      const emailDomain = email.split('@')[1];
      const emailEntrepriseDomain = entreprise.entrepriseNomDomaine.find((e) => e.nomDomaine === emailDomain);

      console.log(emailEntrepriseDomain);

      return !!(emailEntrepriseDomain && emailEntrepriseDomain.hasSsoAuth);
    },
    [loggedUser]
  );
}
